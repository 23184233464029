<template>
  <span class="hidden-lg-and-up">
    <v-app-bar-nav-icon @click="clickDriwer"></v-app-bar-nav-icon>
  </span>
</template>

<script>
export default {
  name: "DefaultDriwerSelector",
  data() {
    return {
      driver: false,
    };
  },

  methods: {
    clickDriwer() {
      this.$emit("click-driwer", (this.driwer = !this.driwer));
    },
  },
};
</script>
